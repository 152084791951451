import React, {useState, useContext} from 'react';
import PropTypes from 'prop-types';
import {PropTypes as MobXPropTypes} from 'mobx-react';

import {CALL_NOW_BTN_PRIMARY, CTA_CALL_NOW} from 'core/constants.js';
import {HealthFundStoreContext} from 'core/stores/RootStore.js';
import {ButtonV2, ModalV2} from '@HealthShareAU/hs-component-library';
import ContactModalV2 from './ContactModalV2';

import {
    PROFILE_CONTACT_TYPE,
    PRACTICE_LOC_CONTACT_TYPE,
} from 'core/constants.js';

const CallNowBtn = ({
    colorTheme,
    customTrigger,
    gaObjectType,
    gaSelector = CALL_NOW_BTN_PRIMARY,
    modalContent,
}) => {
    const healthFundStore = useContext(HealthFundStoreContext);
    const [open, setOpen] = useState(false);

    if (
        !modalContent ||
        (!modalContent.phoneNumber &&
            !modalContent.items?.filter((item) => item.phones?.length).length)
    ) {
        return null;
    }

    const handleClick = () => modalContent && setOpen(true);
    return (
        <>
            {customTrigger ? (
                customTrigger(handleClick)
            ) : (
                <ButtonV2
                    customClass="hs-call-now-btn-v2"
                    dataTestId={CTA_CALL_NOW}
                    gaSelector={gaSelector}
                    gaObjectType={gaObjectType}
                    icon="phoneCall"
                    onClick={handleClick}
                    text="Call the practice"
                    type="secondary"
                />
            )}
            {open && (
                <ModalV2
                    colorTheme={colorTheme}
                    handleClose={() => setOpen(false)}
                    isOpen={open}
                >
                    <ContactModalV2
                        {...modalContent}
                        healthFundId={healthFundStore?.healthFund?.id}
                    />
                </ModalV2>
            )}
        </>
    );
};
CallNowBtn.propTypes = {
    modalContent: PropTypes.shape({
        phoneNumber: PropTypes.string,
        items: MobXPropTypes.arrayOrObservableArrayOf(
            PropTypes.shape({
                phones: MobXPropTypes.arrayOrObservableArrayOf(
                    PropTypes.shape({
                        number: PropTypes.string.isRequired,
                    }),
                ).isRequired,
                type: PropTypes.oneOf([
                    PROFILE_CONTACT_TYPE,
                    PRACTICE_LOC_CONTACT_TYPE,
                ]).isRequired,
            }),
        ),
    }).isRequired,
};
CallNowBtn.displayName = 'CallNowBtn';
export default CallNowBtn;
