import React, {useContext} from 'react';

import {LinkV2} from '@HealthShareAU/hs-component-library';
import {
    BOOK_NOW_BTN_CUSTOM_URL_TEST_ID,
    BOOK_NOW_BTN_BKG_PROD_PRIMARY,
} from 'core/constants.js';

import {ParamStoreContext} from 'core/stores/RootStore.js';

const CustomBookingBtn = ({
    colorTheme,
    customBookingDataUrl,
    gaObjectType,
}) => {
    if (!customBookingDataUrl) {
        return null;
    }

    const paramStore = useContext(ParamStoreContext);
    const {isReferrals} = paramStore;
    if (isReferrals) {
        return null;
    }

    return (
        <LinkV2
            asButton
            colorTheme={colorTheme}
            customClass="primary"
            dataTestId={BOOK_NOW_BTN_CUSTOM_URL_TEST_ID}
            gaSelector={BOOK_NOW_BTN_BKG_PROD_PRIMARY}
            gaObjectType={gaObjectType}
            href={customBookingDataUrl}
            iconLeft="calendarCheck"
            target="_blank"
            text="Book now"
        />
    );
};
export default CustomBookingBtn;
