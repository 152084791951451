import React, {useState, useContext} from 'react';
import PropTypes from 'prop-types';
import {PropTypes as MobXPropTypes} from 'mobx-react';

import RequestApptModal from 'core/components/v2/RequestApptModal.js';
import {BOOK_NOW_BTN_REQ_PRIMARY, CTA_REQUEST_APPT} from 'core/constants.js';

import {ButtonV2, ModalV2} from '@HealthShareAU/hs-component-library';

import {ParamStoreContext} from 'core/stores/RootStore.js';

const RequestApptBtn = ({
    requestApptEmail,
    colorTheme,
    gaObjectType,
    gaSelector = BOOK_NOW_BTN_REQ_PRIMARY,
    modalContent,
}) => {
    const [open, setOpen] = useState(false);
    const paramStore = useContext(ParamStoreContext);
    const {hasSkin, isReferrals} = paramStore;
    if (!requestApptEmail || hasSkin || isReferrals) {
        return null;
    }
    return (
        <>
            <ButtonV2
                colorTheme={colorTheme}
                dataTestId={CTA_REQUEST_APPT}
                gaSelector={gaSelector}
                gaObjectType={gaObjectType}
                icon="send"
                onClick={() => modalContent && setOpen(true)}
                text="Request an appointment"
            />
            {open && (
                <ModalV2
                    colorTheme={colorTheme}
                    handleClose={() => setOpen(false)}
                    isOpen={open}
                >
                    <RequestApptModal
                        colorTheme={colorTheme}
                        {...modalContent}
                    />
                </ModalV2>
            )}
        </>
    );
};
RequestApptBtn.displayName = 'RequestApptBtn';
RequestApptBtn.propTypes = {
    requestApptEmail: PropTypes.string.isRequired,
    modalContent: PropTypes.shape({
        specialties: MobXPropTypes.arrayOrObservableArray.isRequired,
    }).isRequired,
};
export default RequestApptBtn;
