import React from 'react';
import PropTypes from 'prop-types';

import autobind from 'common/decorators/autobind.js';
import events from 'events.js';
import {ButtonV2 as Button} from '@HealthShareAU/hs-component-library';

export default class ButtonV2 extends React.Component {
    static propTypes = {
        action: PropTypes.func.isRequired,
        colorTheme: PropTypes.object,
        customClass: PropTypes.string,
        customRef: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.shape({current: PropTypes.instanceOf(Element)}),
        ]),
        dataTestId: PropTypes.string,
        disabled: PropTypes.bool,
        gaObjectType: PropTypes.string,
        gaSelector: PropTypes.string,
        icon: PropTypes.string,
        isPreventDefault: PropTypes.bool,
        onKeyDown: PropTypes.func,
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
            .isRequired,
        title: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.ref = props.customRef || React.createRef();
    }

    static defaultProps = {
        disabled: false,
        type: 'button',
    };

    componentDidMount() {
        events.listen(this.ref.current, 'mousedown', this.handleMouseDown);
    }

    componentWillUnmount() {
        events.unlisten(this.ref.current, 'mousedown', this.handleMouseDown);
    }

    @autobind
    handleMouseDown(event) {
        if (this.props.preventDefault) {
            event.preventDefault();
        }
    }

    @autobind
    handleClick(event) {
        const {action, isPreventDefault} = this.props;
        if (isPreventDefault) {
            event.preventDefault();
        }
        action(event);
    }

    render() {
        const {
            customClass,
            colorTheme,
            dataTestId,
            disabled,
            gaObjectType,
            gaSelector,
            onKeyDown,
            text,
            icon,
        } = this.props;

        return (
            <Button
                colorTheme={colorTheme}
                customClass={customClass}
                gaObjectType={gaObjectType}
                gaSelector={gaSelector}
                dataTestId={dataTestId}
                disabled={disabled}
                onClick={this.handleClick}
                onKeyDown={onKeyDown}
                componentRef={this.ref}
                icon={icon}
                text={text}
            />
        );
    }
}
