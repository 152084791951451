import React from 'react';
import PropTypes from 'prop-types';
import {PropTypes as MobXPropTypes} from 'mobx-react';

import http from 'http.js';
import query from 'query.js';

import autobind from 'common/decorators/autobind.js';
import Icon from 'core/components/Icon';
import LinkWithPropertiesV2 from 'core/components/v2/LinkWithPropertiesV2';
import ReCaptcha from 'core/components/ReCaptcha';
import {isValidAustralianPhoneNumber} from 'utils/validation.js';
import {
    ButtonV2,
    CardV2,
    FeedbackBoxV2,
    FormV2,
    HeaderTitleV2,
    InputV2,
    LinkV2,
    NotificationV2,
} from '@HealthShareAU/hs-component-library';

const GP_ID = 63;
export const YES = 'yes';
export const NO = 'no';

export default class RequestApptModal extends React.Component {
    static propTypes = {
        avatar: PropTypes.string,
        colorTheme: PropTypes.shape({
            color: PropTypes.string,
        }),
        displayName: PropTypes.string.isRequired,
        displayScreenerQuestion: PropTypes.bool,
        isReferrals: PropTypes.bool,
        specialties: MobXPropTypes.arrayOrObservableArrayOf(
            PropTypes.shape({
                isMedical: PropTypes.bool,
                isSpecialist: PropTypes.bool,
                name: PropTypes.string.isRequired,
                professionalName: PropTypes.string,
            }),
        ).isRequired,
    };

    static defaultProps = {
        displayScreenerQuestion: true,
        isReferrals: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            captchaToken: null,
            formNotification: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            referralObtained: null,
            showFirstNameError: false,
            showLastNameError: false,
            showPhoneNumberError: false,
            submitting: false,
        };
    }

    get linkParams() {
        const {localityId} = this.props;
        const params = {
            specialty: GP_ID,
            locality: localityId,
        };
        const hf = query.parse().health_fund;
        if (hf) {
            params['health_fund'] = hf;
        }
        return params;
    }

    get formAction() {
        const {id} = this.props;
        return `/api/practices/v1/positions/${id}/request-appointment-email/`;
    }

    @autobind
    async handleFormSubmit(event) {
        if (event) {
            event.preventDefault();
        }

        if (!this.isValidForm) {
            this.setValidationMessages();
            return null;
        }

        const {id} = this.props;
        const {
            captchaToken,
            firstName,
            lastName,
            phoneNumber,
            referralObtained,
        } = this.state;
        const url = `/api/practices/v1/positions/${id}/request-appointment-email/`;
        const data = {
            'captcha_token': captchaToken,
            'full_name': `${firstName} ${lastName}`,
            'phone_number': phoneNumber,
        };
        if (referralObtained !== null) {
            data['has_referral_letter'] = referralObtained === YES;
        }
        this.setState({submitting: true});
        let formNotification = 'success';
        try {
            await http.post({url, data});
        } catch (error) {
            formNotification = 'error';
            throw new Error(error);
        } finally {
            this.setState({formNotification, submitting: false});
        }
    }

    @autobind
    handleFirstNameChange(firstName) {
        this.setState({firstName});
    }

    @autobind
    handleLastNameChange(lastName) {
        this.setState({lastName});
    }

    @autobind
    handlePhoneNumberChange(phoneNumber) {
        this.setState({phoneNumber});
    }

    @autobind
    setCaptchaToken(captchaToken) {
        this.setState({captchaToken});
    }
    @autobind
    validateFirstName() {
        if (!this.isValidFirstName) {
            this.setState({showFirstNameError: true});
        }
    }
    @autobind
    validateLastName() {
        if (!this.isValidLastName) {
            this.setState({showLastNameError: true});
        }
    }
    @autobind
    validatePhoneNumber() {
        if (!this.isValidPhoneNumber) {
            this.setState({showPhoneNumberError: true});
        }
    }
    @autobind
    resetFieldValidation(fieldName) {
        const stateKey = `show${fieldName}Error`;
        this.setState({[stateKey]: false});
    }
    get isValidFirstName() {
        return (
            !!this.state.firstName &&
            /^[A-Za-z\s]+$/.test(this.state.firstName)
        );
    }

    get isValidLastName() {
        return (
            !!this.state.lastName && /^[A-Za-z\s]+$/.test(this.state.lastName)
        );
    }

    get isValidPhoneNumber() {
        return isValidAustralianPhoneNumber(this.state.phoneNumber);
    }

    get isValidForm() {
        return (
            this.isValidPhoneNumber &&
            this.isValidFirstName &&
            this.isValidLastName
        );
    }

    get specialtyName() {
        const {isReferrals, specialties} = this.props;
        return specialties
            .map((sp) => (isReferrals ? sp.professionalName : sp.name))
            .join(', ');
    }

    @autobind
    setValidationMessages() {
        this.setState({
            showFirstNameError: !this.isValidFirstName,
            showLastNameError: !this.isValidLastName,
            showPhoneNumberError: !this.isValidPhoneNumber,
        });
    }

    @autobind
    handleOptionSelect(event) {
        this.setState({
            referralObtained: event.target.value,
        });
    }

    renderAnswer(value, label) {
        const {referralObtained} = this.state;
        return (
            <li>
                <label>
                    <input
                        checked={referralObtained === value}
                        name="question"
                        onChange={this.handleOptionSelect}
                        type="radio"
                        value={value}
                    />
                    <Icon name="radio" />
                    <p className="has-margin">{label}</p>
                </label>
            </li>
        );
    }

    @autobind
    handleReviewSubmission() {
        this.setState({
            formNotification: '',
        });
    }

    renderNotification() {
        const {colorTheme, displayName, isReferrals} = this.props;
        const {formNotification} = this.state;

        return formNotification === 'success' ? (
            <NotificationV2
                customClass="bordered has-margin"
                displayIcon
                text={`Thank you for requesting an appointment with ${displayName}. Your ${
                    isReferrals ? 'patient’s ' : ''
                }information has been provided to the practice and they will get in contact to set up an appointment.`}
                type="success"
            />
        ) : (
            <NotificationV2
                customClass="bordered request-appt-modal-v2__failed-alert"
                displayIcon
                text={
                    <>
                        {
                            'An error has occurred please refresh and try again. '
                        }
                        <ButtonV2
                            colorTheme={colorTheme}
                            customClass="bordered request-appt-modal-v2__failed-alert-btn"
                            onClick={this.handleReviewSubmission}
                            text={'Review submission'}
                        />
                    </>
                }
                type="error"
            />
        );
    }

    renderReferralMessage() {
        const {localitySb, colorTheme} = this.props;

        return (
            <FeedbackBoxV2
                colorTheme={colorTheme}
                boxType="info"
                descriptionText={
                    'Before you can proceed with requesting an appointment you will need to get a referral letter from a GP. '
                }
                children={
                    <LinkWithPropertiesV2
                        colorTheme={colorTheme}
                        customClass="hs-link-v2"
                        href="/directory/"
                        searchParams={this.linkParams}
                        text={`View GPs in ${localitySb}`}
                    />
                }
            />
        );
    }

    render() {
        const {
            address,
            avatar,
            colorTheme,
            displayName,
            displayScreenerQuestion,
            isReferrals,
            profileUrl,
        } = this.props;

        const {
            formNotification,
            showFirstNameError,
            showLastNameError,
            showPhoneNumberError,
            referralObtained,
            submitting,
        } = this.state;

        const googlePreviewUrl =
            address && `https://www.google.com/maps/preview/?q=${address}`;

        return (
            <div className="appointment-modal-v2">
                <HeaderTitleV2
                    heading="Request an appointment"
                    headerType="submain"
                />
                <CardV2
                    address={
                        googlePreviewUrl ? (
                            <LinkV2 href={googlePreviewUrl} text={address} />
                        ) : (
                            address
                        )
                    }
                    avatar={{src: avatar}}
                    colorTheme={colorTheme}
                    header={displayName}
                    href={profileUrl}
                    subheader={this.specialtyName}
                    type="summary"
                />
                <div className="request-appt-modal-v2">
                    {formNotification ? (
                        this.renderNotification()
                    ) : (
                        <>
                            <p className="has-margin">
                                {`Please provide your${
                                    isReferrals ? " patient's" : ''
                                } details and the practice will contact ${
                                    isReferrals ? 'them' : 'you'
                                } to book an appointment.`}
                            </p>
                            {displayScreenerQuestion && (
                                <div className="question">
                                    <h2>
                                        {isReferrals
                                            ? 'Does your patient have a referral?'
                                            : 'Do you have a referral?'}
                                    </h2>
                                    <ul className="options">
                                        {this.renderAnswer(
                                            YES,
                                            `Yes, ${
                                                isReferrals ? 'they' : 'I'
                                            } have a referral`,
                                        )}
                                        {this.renderAnswer(
                                            NO,
                                            `No, ${
                                                isReferrals ? 'they' : 'I'
                                            } do not have a referral`,
                                        )}
                                    </ul>
                                </div>
                            )}
                            {(referralObtained === YES ||
                                !displayScreenerQuestion) && (
                                <>
                                    <FormV2
                                        action={this.formAction}
                                        customClass="request-appt-modal-v2__form"
                                        onSubmit={this.handleFormSubmit}
                                    >
                                        <div className="request-appt-modal-v2__input-fields">
                                            <InputV2
                                                errorMsg={
                                                    'Please provide a valid First name'
                                                }
                                                id="first-name"
                                                isInvalid={showFirstNameError}
                                                isPrivate={true}
                                                label="First Name"
                                                onBlur={this.validateFirstName}
                                                onChange={
                                                    this.handleFirstNameChange
                                                }
                                                onFocus={() =>
                                                    this.resetFieldValidation(
                                                        'FirstName',
                                                    )
                                                }
                                                value={this.state.firstName}
                                            />
                                            <InputV2
                                                errorMsg={
                                                    'Please provide a valid Last name'
                                                }
                                                id="last-name"
                                                isInvalid={showLastNameError}
                                                isPrivate={true}
                                                label="Last Name"
                                                onBlur={this.validateLastName}
                                                onChange={
                                                    this.handleLastNameChange
                                                }
                                                onFocus={() =>
                                                    this.resetFieldValidation(
                                                        'LastName',
                                                    )
                                                }
                                                value={this.state.lastName}
                                            />
                                            <InputV2
                                                errorMsg={
                                                    'Please provide a valid phone number'
                                                }
                                                id="phone-number"
                                                isInvalid={
                                                    showPhoneNumberError
                                                }
                                                isPrivate={true}
                                                label="Phone Number"
                                                onBlur={
                                                    this.validatePhoneNumber
                                                }
                                                onChange={
                                                    this
                                                        .handlePhoneNumberChange
                                                }
                                                onFocus={() =>
                                                    this.resetFieldValidation(
                                                        'PhoneNumber',
                                                    )
                                                }
                                                value={this.state.phoneNumber}
                                            />
                                        </div>
                                        <ReCaptcha
                                            action="request_appointment_form"
                                            setCaptchaToken={
                                                this.setCaptchaToken
                                            }
                                        />
                                        <ButtonV2
                                            colorTheme={colorTheme}
                                            action={() => {
                                                // Use form submit
                                            }}
                                            buttonType="submit"
                                            disabled={submitting}
                                            text={'Submit appointment request'}
                                        />
                                    </FormV2>
                                    <small className="request-appt-moda-v2__footer">
                                        {`HealthShare will handle personal information in accordance with the `}
                                        <LinkV2
                                            href={
                                                'https://www.healthshare.com.au/privacy-policy/'
                                            }
                                            isExternalLink={true}
                                            text="Privacy Policy"
                                        />
                                        {'.'}
                                    </small>
                                </>
                            )}
                            {referralObtained === NO &&
                                this.renderReferralMessage()}
                        </>
                    )}
                </div>
            </div>
        );
    }
}
